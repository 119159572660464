.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.makeStyles-button-105 {
  margin: 8px !important;
}

/* Workaround: der ReactRouter löscht Elemente aus dem Komponentenbaum, wenn dieses nicht angezeigt werden. Dadurch entstehen im Grid Container ohne Inhalt.
Diese haben durch das Spacing ein negatives margin aber keine Höhe. Dadurch wird das Layout fehlerhaft -> Daher werden leere Container auf display:none gesetzt*/
.MuiGrid-container:empty {
  display: none;
}

/* Temporär für die Demo */
.MuiExpansionPanel-root.Mui-expanded:last-child {
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.MuiExpansionPanelSummary-root {
  padding: 0px !important;
}
