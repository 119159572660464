body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
